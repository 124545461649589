.mypage-modal {
  height: 100%;
  margin: 0 auto;
  max-width: 600px;
  position: relative;
  width: 100%;
  z-index: 1001;
  outline: none;
}

.mypage-modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  outline: none;

  overflow: scroll;
}

.mypage {
  width: 80%;
  max-width: 480px;
  min-height: 100%;
  padding-bottom: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--mypage-color);
  color: var(--mypage-font-color);
  z-index: 1001;
  outline: none;

}

.mypage-title {
  padding-left: 1rem;
  height: 50px;
  line-height: 50px;
  font-size: 1.5rem;
  font-weight: bold;

  float: left;
}

.mypage-close-icon {
  padding-right: 1rem;
  height: 50px;
  color: var(--mypage-close-icon-font-color);

  line-height: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;

  float: right;

  cursor: pointer;
}

.clearfix {
  clear: both;
  width: 100%;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--line-color);
}

/* .maypage-language-change{
  float: left;
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1rem;
} */

.maypage-language-change-button{
  border: none;
  width: 8rem;
  height: 3rem;
  font-weight: bolder;
  -webkit-text-stroke: 0.1px #e3e3e3;
  background-color: white;
  margin: 0px auto;
  padding: 0px 5px;
}
.maypage-language-change-button:hover{
  /* background-color: rgb(255, 255, 255); */
  background-color: white;
}

.mypage-balance {
  border: 1.5px solid var(--line-color);
  border-radius: 8px;
  margin: 5rem 1rem 1rem;
}

.mypage-balance-title {
  margin: 1rem 0;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;

  float: left;
}

.mypage-balance-point {
  margin: 1rem 0;
  padding: 1rem 2rem 0.5rem 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;

  height: 2.5rem;
  line-height: 2.5rem;

  float: right;
}

.mypage-balance-point img {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.maypage-line{
  margin: 0.5rem auto;
  width: 90%;
  height: 1px;
  background-color: var(--line-color);
}
.mypage-balance-button {
  width: 100%;
  text-align: center;

  clear: both;
  padding-bottom: 1rem;
}

.mypage-balance-button button {
    margin: 0.5rem auto;
    width: 90%;
    height: 5rem;
    border-radius: 8px;
    cursor: pointer;
    border-width: 1.5px;
}

.mypage-balance-buy-button {
  text-shadow:
           1px 1px 0px #FFF, -1px -1px 0px #FFF,
          -1px 1px 0px #FFF,  1px -1px 0px #FFF,
           1px 0px 0px #FFF, -1px  0px 0px #FFF,
           0px 1px 0px #FFF,  0px -1px 0px #FFF;
  color: var(--mypage-balance-buy-button-font-color);
  background: var(--mypage-balance-buy-button-color) 0% 0% no-repeat padding-box;
  border: none;
}

.mypage-confirm-bought-button {
  color: var(--mypage-balance-buy-button-font-color);
  background: var(--mypage-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--mypage-balance-buy-button-color);
}

.mypage-ship {
  margin-top: 6vh;
  padding: 2rem;
}

.mypage-ship-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.mypage-ship-info {
  max-height: 8rem;
  width: 100%;
  position: relative;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;

  color: var(--mypage-close-icon-font-color);

  background: var(--mypage-color) 0% 0% no-repeat padding-box;
  border: 1.5px solid #707070;
  border-radius: 8px;

  cursor: pointer;
}

.mypage-ship-info div {
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mypage-ship-info::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.4rem);
  width: 0.8rem;
  height: 0.8rem;
  border-top: solid 2px #BCBCBC;
  border-right: solid 2px #BCBCBC;
  transform: rotate(45deg);
}

.mypage-ship-info-name {
  font-size: 1.1rem;
}

.mypage-footer {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    bottom: 1rem;
    width: 100%;

    color: var(--mypage-footer-color);
}

.mypage-footer a {
    font-size: 1.1rem;

    color: var(--mypage-footer-color);
}

.mypage-footer div {
    margin: 0.5rem 0.5rem;
    float: left;
}

.item-nav {
  margin: 1rem 0;
  width: 100%;
  height: 2.5em;

  border-bottom: 1px solid var(--hold-menu-color);
}

.item-types {
  width: 100%;
}
.item-type {
  float: left;
  list-style: none;
  margin: 0 0.5em;

  color: var(--hold-menu-font-color);

  font-weight: bold;

  cursor: pointer;
}
.item-type-selected {
  color: var(--hold-menu-selected-font-color);

  height: 2.5em;
  border-bottom: 1px solid var(--hold-menu-selected-color);
}

.card-name {
  width: 60%;

  font-size: 1.2rem;
  font-weight: bold;
  
  float: right;
  clear: left;
}

.card-rarity {
  width: 60%;

  font-size: 1.2rem;
  font-weight: bold;

  color: var(--card-rarity-color);
  
  float: right;
  clear: left;
}

.card-point {
  width: 60%;

  font-size: 1.2rem;
  font-weight: bold;
  
  float: right;
  clear: left;
}

.cards {
  width: 100%;
  margin: 3rem 0;
  padding-bottom: 50vh;
}

.card {
  position: relative;

  width: 90%;
  height: 5.5rem;

  margin: 2rem auto;
  padding: 1rem 0;

  border: 4px solid var(--card-color);
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;

  background-color: #FFF;
}

.card-checkbox {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;

  width: 2rem;
  height: 2rem;

  border: none;
  border-radius: 1rem;
}

.card-checkbox input {
  visibility: hidden;
}


.card-image {
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
}

.card-image img{
  height: 8rem;
  
  aspect-ratio: 1/1.5;
}

.card-balance-image {
  position: absolute;
  top: 50%;
  left: 6rem;
}

.card-balance-image img {
  width: 2.5rem;
  height: 2.5rem;

}

.card-no-item {
  font-size: 1.1rem;
  font-weight: bold;
}

.user-address {
  width: 100%;
  height: 100%;
}

.user-address-title {
  padding-left: 1rem;
  height: 50px;
  line-height: 50px;
  font-size: 1.5rem;
  font-weight: bold;

  text-align: center;

  color: var(--user-address-title-color);
}

.user-address-list {
  margin-bottom: 5rem;
}

.user-address-item {
  position: relative;
  width: calc(100% - 7rem);
  padding: 2rem 1rem 2rem 6rem;
  margin: 1rem auto;

  color: var(--user-address-item-font-color);

  background: var(--user-address-item-color) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;

  cursor: pointer;
}

.user-address-item-radio {
  position: absolute;
  top: calc(50% - 1rem);
  left: 1rem;
}

.user-address-item-radio input {
  width: 1.5rem;
  height: 1.5rem;

  accent-color: var(--gacha-movie-button-font-color);
}

.user-address-item-name {
  color: var(--user-address-item-name-color);
  font-size: 1.2rem;
  font-weight: bold;
  width: 70%
}

.user-address-item-postcode {
  font-size: 1.1rem;
  width: 70%
}

.user-address-item-address {
  font-size: 1.1rem;
  width: 70%
}

.user-address-item-button {
  position: absolute;
  top: calc(50% - 3rem);
  right: 1rem;
}

.user-address-item-button button {
  display: block;
  width: 6.5rem;
  height: 2.5rem;

  margin: 0.5rem 0;

  border-radius: 8px;
}

.user-address-item-edit-button {
  color: var(--user-address-button-close-color);
  background: var(--user-address-item-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--user-address-item-edit-button-color);
  border-radius: 4px;
}

.user-address-item-delete-button {
  border: none;
  color: var(--user-address-item-delete-button-color);
  background: var(--user-address-item-color) 0% 0% no-repeat padding-box;
}

.user-address-button {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  text-align: center; /* 追加 */
} 

.user-address-button button {
  display: inline-block; /* 'block' を 'inline-block' に変更 */
  width: 90%;
  height: 3.5rem;

  margin: 0.5rem 0;

  border: none;
  border-radius: 8px;
}

.user-address-button-add {
  color: var(--user-address-button-add-font-color);
  background: var(--user-address-button-add-color) 0% 0% no-repeat padding-box;
}

.user-address-button-close {
  color: var(--user-address-form-button-next-font-color);
  background: var(--user-address-button-close-color) 0% 0% no-repeat padding-box;
}

.hold-menus {
  margin: 0 auto;
  width: 90%;
  height: 2.5em;
  padding-bottom: 1.5em;

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.hold-menu {
  width: 33%;

  text-align: center;

  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  border-bottom: 1px solid var(--hold-menu-color);
  color: var(--hold-menu-font-color);
}
.hold-menu {
  display: inline-block;

  list-style: none;
  padding: 0 0 0.5rem 0;

  font-weight: bold;
  cursor: pointer;
}

.hold-menu-selected {
  border-bottom: 1px solid var(--hold-menu-selected-color);
  color: var(--hold-menu-selected-font-color);
}

.mypage-balance-expire {
  clear: both;
  float: right;

  margin-right: 2rem;
  margin-bottom: 2rem;

  text-align: right;
}

.user-address-form {
  color: var(--user-address-title-color);
}

.user-address-form::placeholder {
  color: var(--user-address-form-placeholder-color);
}

.user-address-form-left {
  width: 40%;
  float: left;

  margin-top: 1rem;
  margin-left: 10%;
}

.user-address-form-right {
  width: 40%;
  float: left;

  margin-top: 1rem;
}

.user-address-form-both {
  width: 80%;
  margin: 1rem auto 0;
}

.user-address-form-left-input {
  height: 3rem;
  width: 85%;
  padding: 0 0.5rem;
  margin-top: 0.5rem;

  color: var(--user-address-title-color);

  border: none;
  background: var(--user-address-form-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;

  font-size: 1.2rem;
}

.user-address-form-right-input {
  height: 3rem;
  width: 85%;
  padding: 0 0.5rem;
  margin-top: 0.5rem;

  color: var(--user-address-title-color);

  border: none;
  background: var(--user-address-form-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;

  font-size: 1.2rem;
}

.user-address-form-both-input {
  width: 100%;
  height: 3rem;
  padding: 0 0.5rem;
  margin-top: 0.5rem;

  color: var(--user-address-title-color);

  border: none;
  background: var(--user-address-form-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;

  font-size: 1.2rem;
}

.user-address-form-left-select {
  width: 100%;
  height: 3rem;
  padding: 0 0.5rem;

  color: var(--user-address-title-color);

  border: none;
  background: var(--user-address-form-color) 0% 0% no-repeat padding-box;
  border-radius: 8px;

  font-size: 1.2rem;
}

.clearfix {
  width: 100%;
  clear: both;
}

.user-address-form-button {
  width: 100%;
  height: 100%;

  margin: 2rem auto;

  text-align: center;
}

.user-address-form-button-back {
  height: 3rem;
  width: 35%;
  margin: 0 0.5rem;

  color: var(--user-address-form-button-back-font-color);
  font-weight: bold;

  background: var(--user-address-form-button-back-color) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
}

.user-address-form-button-next {
  height: 3rem;
  width: 35%;
  margin: 0 0.5rem;

  color: var(--user-address-form-button-next-font-color);
  font-weight: bold;

  background: var(--user-address-form-button-next-color) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
}

.no-card {
  margin: 0 auto;
  width: 90%;
  height: 2.5em;
  padding-bottom: 1.5em;
}

.policy-contents {
  width: 90%;
  margin: 0 auto;

  margin-bottom: 2rem;
}

.user-info {
  width: 95%;
  margin: 1rem auto;

  font-size: 1.2rem;
}

.user-info-logout {
  margin-top: 0.25rem;
  margin-right: 2rem;
  padding: 0.25rem 0;
  float: right;
  width: 8rem;
  height: 2.5rem;

  font-size: 1.2rem;
  text-align: center;

  color: var(--mypage-balance-buy-button-font-color);
  background: var(--mypage-color) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--mypage-balance-buy-button-color);
  border-radius: 4px;

  cursor: pointer;
}

.credit-card-list {
  width: 98%;

  margin: 0 auto;
  text-align: center;
}

.credit-card-list-ul {
  width: 100%;
}

.credit-card-list-li {
  position: relative;
  list-style:none;
  margin: 1rem auto;

  width: 100%;
  height: 7rem;

  color: var(--user-address-item-font-color);
  background: var(--user-address-item-color) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;

  font-size: 1.5rem;
}

.card-brand {
  padding: 2rem;
  width: 4rem;
  float: left;
}

.card-info {
  margin-left: 10rem;
  padding: 2rem;
  width: calc(90% - 10rem);

  text-align: left;
}

.card-delete {
  position: absolute;
  
  right: 1rem;
  bottom: 1rem;
}

.card-delete button {
  border: none;
  background: none;

  color: var(--user-address-item-delete-button-color);

  text-decoration: underline;
}

.credit-card-new-button {
  margin: 3rem 0 1rem 0;
  font-size: 1rem;

  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 3rem;
  font-weight: bold;

  color: var(--mypage-balance-buy-button-font-color);
  background: var(--mypage-balance-buy-button-color) 0% 0% no-repeat padding-box;
  border: none;
}

.padding-payment {
  height: 5rem;
}

/* .contents.override {
  width: 100%;
  margin: 0 auto;
  clear: both;

  min-height:80vh;
  min-height:80dvh;
} */