header {
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
    left: center;

    width: 100%;
    max-width: 600px;
    height: 8vh;

    padding: 0 0.5rem;
    background-color: var(--header-color);  

    z-index: 10;
    /* box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.1); */
}
.headerShadow {
    background-color: var(--header-color);  
    max-width: 600px;
    position: fixed;
    top: 0;

    width: 100%;
    height: 8vh;
    z-index: 9;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 30%;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img {
    width: 100%;
    aspect-ratio: 15/3;
}

.user {
    display: flex;
    align-items: center;
    
    position: absolute; 
    right: 5px;

    height: 8vh;
}
.user img{
    width: 30px;
    height: 30px;
}
.login-user {
    display: flex;
    align-items: center;
    
    position: absolute; 
    right: 5px;

    width: 10rem;
    height: 8vh;
}
.user-button {
    width: 100%;
    height: 5vh;
    padding: 5px 0px;
    margin: 0px auto;

    font-size: x-small;
    font-weight: 600;

    border-radius: 5px;
    /* border: 1px solid rgba(0, 0, 0, 0.231); */
    border: 1px solid white;
    /* background-color: white; */
    background-color: #FDD526;
}
.user-button:hover{
    /* background-color: rgb(242, 241, 241); */
    background-color: #FDD526;
}

.user img {
    width: 100%;
    cursor: pointer;
}
.balance {
    display: flex;
    align-items: center;
    
    position: absolute; 
    left: 5px;

    height: 8vh;

    margin: auto 0px;



    overflow: hidden;
}
.balance-icon {
    float: left;
    width: 30px;
    height: 30px;

    margin-left: 5px;
}
.balance-icon img {
    margin-top: 10%;
    width: 80%;
}
.balance-pt {
    display: flex;
    text-align: center;
    align-items: center;
    float: right;
    padding-right: 1rem;
    margin-right: 0.5em;

    height: 31px;
    
    min-width: 8rem;
    border-radius: 20px;

    background-color: var(--balance-color);
    color: var(--balance-pt-font-color);
}
.balance-pt-value {
    font-weight: 600;
    margin-right: 1rem;
}

.language-change {
    display: flex;
    align-items: center;

    position: absolute; 
    left: 5px;

    width: 12rem;
    height: 4vh;

    margin: 2vh 1vh 2vh 0vh;
}
.language-image{
    height: 2rem;
    width: 2rem;
    float: left;
    margin: auto 0px;
}
.language-change-button{
    border: none;
    width: 8rem;
    height: 3rem;
    font-weight: bolder;
    -webkit-text-stroke: 0.1px #e3e3e3;
    background-color: #FDD526;
    margin: 0px auto;
    padding: 0px 5px;
}
.language-change-button:hover{
    /* background-color: rgb(255, 255, 255); */
    background-color: #FDD526;
}

.language-change ul{
    min-width: 3rem;
}
.language-change li a{
    width: 10rem;
    padding-left: 0.5rem;
}

footer {
    width: 100%;
    height: 12vh;
    text-align: center;
    color: var(--footer-color);
    background-color: #FDD526;

    font-size: auto;
    /* margin-top: auto; */
    position: sticky;
    top: 100vh;
    top: 100dvh;
}
.footer-dammy{
    position: fixed;
    bottom: 0;
    
    width: 101%;
    height: 12.5vh;
    text-align: center;

    background-color: white;
    font-size: auto;
    margin-top: auto;
    z-index: 100;
}
.footer-1{
    height: 40%;
    display: flex;
    color: var(--footer-color);
    padding: 0.5rem 0rem;
    font-weight: bold;
}
.footer-2{
    height: 40%;
    display: flex;
    width: 80%;
    padding: 0.5rem 0rem;
    font-weight: bold;
}
footer a{
    font-weight: lighter;
    color: var(--footer-color);
}
footer a:hover{
    color: var(--footer-color);
}
footer div{
    width: 100%;
    justify-content: center;
    color: var(--footer-color);
    margin:0 auto;
}
.footer-shop{
    height: 20%;
}
.footer-name{
    font-size: 1.1rem;
    width: 60%;
}
.footer-logo {
    float: left;
    width: 20%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-logo img {
    width: 100%;
    aspect-ratio: 15/3;
}
