
* {
  font-size: 14px;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

.flex {
  display: flex;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.w-1\/2 {
  width: 50%;
}

.text-center {
  text-align: center;
}

button {
  cursor: pointer;
}

#root {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  margin: 0 auto;

  -webkit-overflow-scrolling: touch;
}

html,  body { margin: 0; width: 100%; height: 100%; }

#content {
  width: 100%;
  margin: 0 auto;
  clear: both;

  padding-top: 8vh; 
  min-height: 87dvh;
}
#content.hold {
  width: 100%;
  margin: 0 auto;
  clear: both;

  padding-top: 8vh; 
  min-height: 100dvh;
}

.footer-sub {
  margin-top: 5rem;
  text-align: center;
}
