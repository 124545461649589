.point {
    background-color: #F8F8F8;
}

.point-back {
    position: relative;
    padding: 1rem;
    height: 2.5rem;

    font-weight: bold;
}

.point-back button {
    position: absolute;
    /* width: 15rem; */
    height: 3rem;
    padding-left: 3rem;
    padding-right: 2rem;

    
    color: var(--point-back-font-color);
    background: var(--point-back-color) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 8px;
    
    text-align: left;
}

.point-back button::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 1rem;
    width: 0.5rem;
    height: 0.5rem;
    margin: -0.25rem 0 0 0;
    border-top: solid 2px var(--point-back-border-color);
    border-right: solid 2px var(--point-back-border-color);
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
}

.point-title {
    margin: 2.5rem auto 2rem;

    color: var(--point-title-color);
    font-size: 2rem;
    font-weight: 600;

    text-align: center;
}

.current-point {
    width: 100%;

    color: var(--point-title-color);
    text-align: center;
}

.current-point-title {
    margin: 1rem auto 0.5rem;

    font-size: 1.1rem;
}

.current-balance {
    margin: 0 auto;
    width:10rem;
    height: 30px;

    border-radius: 20px;

    background-color: var(--balance-color);

    overflow: hidden;
}
.current-balance-icon {
    float: left;
    width: 30px;
    height: 30px;

    margin-left: 5px;
}
.current-balance-icon img {
    margin-top: 10%;
    width: 80%;
}
.current-balance-pt {
    float: right;
    margin-right: 0.5em;

    text-align: right;
    line-height: 30px;

    color: var(--balance-pt-font-color);
}
.current-balance-pt-value {
    font-weight: 600;
}

.point-description {
    display: flex;
    text-align: center;
    align-items: center;
    margin: 2rem auto 0.5rem;

    width: 80%;
    height: 10vh;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    background-color: #F0F0F0;
    padding: 1rem;
    padding-left: 0.5rem;
    color: #757575;
}

.point-description div{
    width: 95%;
    font-size: 1.3vh;
    margin-left: 0.5rem;
}

.point-description img{
    float: left;
    width: 5%;
    
    margin: auto 0px;
}

.point-list {
    margin: 3rem auto 5rem;
    width: 98%;
}

.point-list-item {
    width: 100%;
    height: 6rem;
    margin: 1rem 0;
    padding-top: 0.5rem;

    color: var(--point-list-item-font-color);
    background: var(--point-list-item-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;

    line-height: 5rem;

    font-size: 1.5rem;
    font-weight: 600;
}

.point-list-item img {
    float: left;
    width: 2.5rem;
    height: 2.5rem;

    margin: 1.3rem 1rem 0 1.5rem;
}

.point-list-item button {
    float: right;
    width: 19.5rem;
    height: 4rem;

    margin: 0.5rem 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    border: none;
    background: var(--point-list-item-button-color) 0% 0% no-repeat padding-box;
    border-radius: 8px;

    font-size: 1.5rem;
    font-weight: 600;
    color: var(--point-list-item-button-font-color);
}

.point-settle-history {
    /* height: 10rem; */
    max-height: 20rem;
    overflow: scroll;
    padding-bottom: 2rem;

    color: var(--point-list-item-font-color);

    font-size: 1.2rem;
    background-color: #F8F8F8;
}

.point-settle-history::-webkit-scrollbar {
    /* width: 20px; */
    display:none; 
}

.point-settle-history-title {
    margin: 1rem auto 0.5rem;

    font-size: 2rem;
    text-align: center;
}

.point-settle-history-list {
    margin: 0 auto;
    margin-bottom: 3rem;
    width: 60%;
}

.point-settle-history-list-item {
    width: 100%;

    color: var(--point-list-item-font-color);
    background: var(--point-list-item-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;

    line-height: 2rem;

    text-align: center;
}

#fincode-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content:center;
  text-align: center;
}

#fincode-list, #fincode-new {
  text-align: left;
}

#fincode {
  position: relative;
  margin-top: -5rem;
  margin-bottom: -5rem;

  z-index: 1;
}

.fincode-amount {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 98%;
  padding: 0 15px;
  border-radius: 8px;

  height: 4rem;
  background: var(--mypage-color);
  margin: 1rem auto 0rem;
}

.fincode-amount div {
    font-size: 1rem;
}

.fincode-amount .fincode-amount-value {
    font-size: 1.3rem;
    font-weight: 600;
}

#fincode-form {
  margin: 0 auto !important;
  box-shadow: none !important;

  background: var(--mypage-color) 0% 0% no-repeat padding-box;
}

.new-card {
  position: relative;
  z-index: 2;
}

.new-card label{
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
}

.new-card input[type=checkbox]{
    transform: scale(1.2);
    margin-right: 0.4rem;
    line-height: 1rem;
    vertical-align: middle;
}

.payment-button button {
    margin: 1.5rem 0 0;
    font-size: 1.2rem;

    width: 90%;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    font-weight: bold;

    color: var(--mypage-balance-buy-button-font-color);
    background: var(--mypage-balance-buy-button-color) 0% 0% no-repeat padding-box;

    position: relative;
    z-index: 2;
}

.e-payment-button button {
    margin: 1rem 0 1rem 0;
    font-size: 1rem;

    width: 90%;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    font-weight: bold;

    color: var(--mypage-balance-buy-button-color);
    background: var(--mypage-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--mypage-balance-buy-button-color);

    position: relative;
    z-index: 2;
}


.shop-payment-error {
    font-size: 1.5rem;
    color: red;
    margin: 1rem auto;
}

.shop-coupon-title {
    font-size: 1.5rem;
}

.shop-coupon-instruction {
    margin: 0.5rem auto;
    font-size: 1.25rem;

}

.shop-coupon-input {
    margin: 0.5rem;
    padding: 1rem;
    width: 90%;
    border-width: 1px;
    border-color: gainsboro;
    border-radius: 8px;

}
.shop-coupon-bottom {
    text-align: center;

}

.shop-coupon-use-button {
    text-align: center;
    margin: 1rem;
    padding: 0.5rem;
    color: #fff;
    font-size: 1.175rem;
    font-weight: bold;
    width: 20%;
    border: none;
    background: var(--point-list-item-button-color) 0% 0% no-repeat padding-box;
    border-radius: 16px;

}

.shop-coupon-use-button:didabled {
    text-align: center;
    margin: 1rem;
    padding: 0.5rem;
    color: #fff;
    font-size: 1.175rem;
    font-weight: bold;
    width: 20%;
    border: none;
    background: gainsboro 0% 0% no-repeat padding-box;
    border-radius: 16px;

}

.g[data-v-4de72cc5] {
  fill: #414b55;
}

.card-select-label {
  color: var(--body-font-color);
  font-size: 1rem;
  line-height: 1.5rem;
  vertical-align: middle;

  margin-left: 0.2rem;
}

.card-select-radio {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  vertical-align: middle;

  accent-color: var(--mypage-balance-buy-button-color);
}

#fincode-list {
  margin: 1rem auto;
  width: 88%;
  
  position: relative;
  z-index: 2
}

#fincode-new {
  margin: 1rem auto 1rem;
  width: 88%;

  position: relative;
  z-index: 2;
}

.card-select-select {
  margin: 1rem auto;
  padding: 0 2vw;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: var(--user-address-title-color);
  background: var(--user-address-form-color) 0 0 no-repeat padding-box;
}

.point {
    position: relative;
}

.country-select {
    text-align: center;
}

.country-select select {
    width: 10rem;
    height: 4rem;
    padding: 0.5rem;

    border: none;
    border-radius: 8px;
    background: var(--mypage-color) 0% 0% no-repeat padding-box;
    color: var(--body-font-color);
}

.country-select img {
    width: 15px;
    height: 15px;
    margin-right: 15px;
}

.country-select button{
    width: 14rem;
}