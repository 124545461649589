.buy-point-confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);

  width: calc(90% - 6rem);
  max-width: 600px;
  height: auto;
  max-height: calc(90vh - 6rem);

  margin: 0 auto;
  padding: 3rem;

  color: var(--point-not-enougth-modal-font-color);;
  background-color: var(--point-not-enougth-modal-color);
  border-radius: 16px;

  overflow-y: auto;

  z-index: 1001;
}

.buy-point-confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
}

.buy-point-confirm-modal-content {
  text-align: center;
  margin: 1rem auto;
}

.buy-point-confirm-modal-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.buy-point-confirm-modal-text {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.buy-point-confirm-modal-button button {
  width: 100%;
  margin: 0.5rem auto;
  padding: 1rem;
  
  border: none;
  border-radius: 8px;

  font-size: 1.2rem;
  cursor: pointer;
}

.buy-point-confirm-modal-button-charge {
  color: var(--point-not-enougth-modal-button-charge-font-color);
  background: var(--point-not-enougth-modal-button-charge-color) 0% 0% no-repeat padding-box;
}

.buy-point-confirm-modal-button-close {
  color: var(--point-not-enougth-modal-button-close-font-color);
  background: var(--point-not-enougth-modal-button-close-color) 0% 0% no-repeat padding-box;
}