.login {
    width: 100%;
    /* height: calc(100vh-5vh); */
    height: 100%;
    margin: 0 auto;
}

.login-title {
    width: 100%;
    margin: auto;
    padding: 3rem 0 1rem 0;

    font-size: 2rem;
    font-weight: bold;

    text-align: center;

    color: var(--login-title-color);
}

.login-form {
    width: 85%;
    margin: 0 auto;

    color: var(--login-title-color);
}

.login-form::placeholder {
    color: var(--login-form-placeholder-color);
}

.login-form dl {
    margin: 0 auto;
    width: 100%;
}

.login-form dt {
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight:500;
}

.login-form dd {
    width: 100%;
    margin-left: 0;
    height: 5rem;
    margin-bottom: 1rem;

    position: relative;
}

.login-form dd input {
    margin: 0 auto;
    padding: 0 1rem;

    width: calc(100% - 2rem);
    height: 4rem;

    font-size: 1.2rem;

    color: var(--login-title-color);

    background: var(--login-form-color) 0% 0% no-repeat padding-box;
    
    border-radius: 8px;
    border: none;
}

.password-toggle-icon {
    position: absolute;
    width: 5rem;
    height: 3rem;

    top: 0.5rem;
    right: 3rem;

    text-align: center;
    padding: 0.3rem 0;

    color: var(--password-toggle-icon-color);

    border: 1px solid var(--password-toggle-icon-color);
    border-radius: 4px;

    cursor: pointer;

}

.login-button button {
    margin: 1rem auto;

    width: 100%;
    height: 5.5rem;
    font-weight: 500;

    color: var(--login-button-font-color);
    background: var(--login-button-color) 0% 0% no-repeat padding-box;
    text-shadow:
           1px 1px 0px #FFF, -1px -1px 0px #FFF,
          -1px 1px 0px #FFF,  1px -1px 0px #FFF,
           1px 0px 0px #FFF, -1px  0px 0px #FFF,
           0px 1px 0px #FFF,  0px -1px 0px #FFF;

    border: none;
    border-radius: 8px;
}

.login-forgot-password {
    width: 100%;
    margin: 1rem auto;

    text-align: center;
    text-decoration:underline;
    text-decoration-color: var(--login-forgot-password-button-color);
}

.login-forgot-password a {
    color: var(--login-forgot-password-button-color);
}

.login-new-register button {
    margin: 1rem auto 2rem;

    width: 100%;
    height: 5.5rem;
    font-weight: bold;

    color: var(--login-button-font-color);
    background: var(--login-new-register-color) 0% 0% no-repeat padding-box;

    border: 1px solid var(--login-button-color);
    border-radius: 8px;
}

.login-back button {
    position: relative;
    margin: 2.5rem auto 10rem;

    width: 100%;
    height: 5.5rem;

    color: var(--login-button-color);
    background: var(--login-new-register-color) 0% 0% no-repeat padding-box;

    border: 1px solid var(--login-button-color);
    border-radius: 8px;
}

.login-back button::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 1rem;
    width: 0.5rem;
    height: 0.5rem;
    margin: -0.25rem 0 0 0;
    border-top: solid 2px var(--login-button-color);
    border-right: solid 2px var(--login-button-color);
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);

}

.login-policy {
    width: 100%;
    margin: 1.5rem auto;

    text-align: left;
}

.login-policy a {
    color: var(--login-button-color);
    text-decoration: none;

    font-size: 1.1rem;
}

.login-policy input {
    width: 1.5rem;
    height: 1.5rem;

    line-height: 1.5rem;
    vertical-align: middle;

    margin-right: 1rem;

    color: var(--login-button-color);
    accent-color: var(--login-button-color);
}

.login-description {
    width: 100%;
    margin: 2rem auto;

    font-size: 1.1rem;
}

.login-back-link {
    width: 100%;
    margin: 1rem auto 10rem;

    text-align: center;
}

.login-back-link a {
    color: var(--login-button-color);
    text-decoration: underline;

    cursor: pointer;
}

.CheckboxInput {
    padding: 12px 0px;
    display: flex;
    align-items: center;
  }
  .CheckboxInput-Input {
    margin: 0px;
    width: 0px;
    opacity: 0;
  }
  .CheckboxInput-Input:checked + .CheckboxInput-DummyInput {
    background: var(--login-button-color);
    border-color: var(--login-button-color);
  }
  .CheckboxInput-Input:checked + .CheckboxInput-DummyInput::before {
    content: "";
    display: block;
    position: absolute;
    top: 25%;
    left: 60%;
    width: 40%;
    height: 4px;
    border-radius: 1px;
    transform: translate(-6px, 5px) rotateZ(-135deg);
    transform-origin: 2px 2px;
    background: var(--login-new-register-color);
  }
  .CheckboxInput-Input:checked + .CheckboxInput-DummyInput::after {
    content: "";
    display: block;
    position: absolute;
    top: 25%;
    left: 60%;
    width: 76%;
    height: 4px;
    border-radius: 1px;
    transform: translate(-6px, 5px) rotateZ(-45deg);
    transform-origin: 2px 2px;
    background: var(--login-new-register-color);
  }
  .CheckboxInput-DummyInput {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: solid 2px transparent;
    border-color: #707070;
    background: var(--login-policy-color);
    border-radius: 4px;
  }
  .CheckboxInput-LabelText {
    margin-left: 12px;
    font-size: 14px;
    display: block;
  }
  .CheckboxInput-LabelText a{
    font-size: 14px;
  }


.login-description {
    width: 100%;
    margin: 2rem auto;

    font-size: 14px;
}

.login-back-link {
    width: 100%;
    margin: 1rem auto 10rem;

    text-align: center;
}

.login-back-link a {
    color: var(--login-button-color);
    text-decoration: underline;

    cursor: pointer;
}

/* 
.footer-login {
    position: relative; 
    z-index: 101; 
    width: 100%;
    height: 55vh;
    text-align: center;
    color: var(--footer-color);
    background-color: #4F4F4F;

    font-size: auto;
    margin-top: auto;
}

.footer-1-login{
    height: 13%;
    display: flex;
    color: white;
    padding: 0.5rem 0rem;
}
.footer-2-login{
    height: 16%;
    display: flex;
    width: 80%;
    padding: 0.5rem 0rem;
}

.footer-shop-login{
    height: 0%;
} */
/* .login-footer {
    margin-bottom: 3rem;
    width: 100%;

    color: #BCBCBC;
}

.login-footer a {
    font-size: 1rem;

    color: #BCBCBC;
}

.login-footer div {
    margin: 0.5rem 0.5rem;
    text-align: center;
} */