@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "FontAwesome", "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: var(--body-font-color);
  background-color: var(--body-color);

  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width:100%;
  height:100%;
  -webkit-overflow-scrolling: touch;

  background-color: white;
}

ul {
  padding-inline-start: 0px;
}