.content {
    width: 100%;
    padding: 1%;
}
.banners {
    width: 98%;
    margin-bottom: 0.5em;
    margin-top: 5rem;
    padding: 1%;
}
.banner {
    width: 100%;

    aspect-ratio:  16/9;
}
.banner img {
    width: 100%;
    
    aspect-ratio:  16/9;
}

.contents {
    width: 100%;
    /* padding: 1%; */

    position: sticky;
    background-color: white;
    top: 7.9vh;
    z-index: 9;

    box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.4);
}
.package-nav {
    width: 100%;
    height: 3em;
    padding-bottom: 1.5em;

    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.package-nav button{
    height: 2.5em;
    margin: 1rem 0rem 0rem 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    border: 1px solid var(--package-type-selected-border-color);
    font-weight: 500;
    font-size: 1rem;
}

.package-types {
    width: 100%;

    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    /* border-bottom: 1px solid var(--package-types-border-color); */
    color: var(--package-types-color);
}
.package-type {
    display: inline-block;

    list-style: none;
    padding: 0 0.5em;

    cursor: pointer;

    color: var(--package-type-selected-color);
    background-color: white;
}

.package-type-selected {
    /* border-bottom: 1px solid var(--package-type-selected-border-color); */
    color: var(--package-type-selected-color);
    background-color: var(--package-type-selected-border-color);
}

.packages {
    width: 99%;
    margin: 20px auto;
}

.package {
    position: relative;
    padding: 0.3rem;

    background-color: var(--package-color);

    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 16px;
    margin: 1rem;
}

.package-sold-out {
    opacity: 0.5;
}

/* .package-sold-out::after {
    content: '完売';
    position: absolute;
    top: 35%;
    width: 100%;

    text-align: center;

    font-size: 3rem;
    font-weight: 600;
    color: #000;

    z-index: 999;
} */

.package-end {
    opacity: 0.5;
}

/* .package-end::after {
    content: '期間終了';
    position: absolute;
    top: 35%;
    width: calc(100% - 32px);

    text-align: center;

    font-size: 3rem;
    font-weight: 600;
    color: #000;

    z-index: 999;
} */


.package-image {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.package-image img {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 1px;
    box-shadow: 0px 3px 6px #03030329;
}
.package-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    /* background: transparent linear-gradient(180deg, #FFFFFF00 80%, var(--package-image-color) 100%) 0% 0% no-repeat padding-box; */
}

.package-cards {
    display:flex;
    overflow-x:auto;
    -webkit-overflow-scrolling: touch; /* iOSデバイスでのスムーズなスクロールを有効にする */
    scrollbar-width: none; /* Firefoxでスクロールバーを非表示にする */

    height: 10vh;
    width: 100%;
    margin: 1.5vh 0;
    justify-content: flex-start;
    /* text-align:center; */
}

.package-cards img {
    height: 100%; /* 親要素の高さに合わせる */
    object-fit: contain; /* 縦横比を保持しつつ親要素のサイズに合わせてスケール */
    width: 100px; /* ここを変更 */
    padding-right: 3%; 
}

.package-cards::-webkit-scrollbar{
    display: none;
}

/* .package-cards img{
    width: calc(100% / 6);
    padding-right: 3%; 
} */

.arrow{
    position: absolute;
    margin-top: 2vh;
    margin-left: 0.5vh;
    width: 6vh;
    height: 6vh;
    border-radius:50%;
    background:rgba(222, 7, 22, 0.8);
    font-size: 30pt;
    font-weight: bolder;
}

.arrow img{
    position: absolute;
    right: 1.5vh;
    top: 1.5vh;
    width: 3vh;
    height: 3vh;
    box-shadow: 0px 0px 0px #03030329;
    padding: 0;
}

.arrow-right {
    right: 0.5vh;
}

.arrow-left {
    left: 0.5vh;
}

.require-pt {
    /* position: absolute; */
    margin: 1rem auto;
    
    width: 12rem;
    height: 30px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    background-color: var(--require-pt-color);

    z-index: 999;
}
.require-pt-icon {
    float: left;
    max-width: calc(30% - 1rem);
    margin-left: 0.5rem;
}

.require-pt-icon img {
    margin-top: 0.7rem;
    height: 1.8rem;

    aspect-ratio: 1 / 1;
}

.require-pt-value {
    font-weight: 600;
    line-height: 30px;
    margin-left: 2.5rem;
    max-width: calc(70% - 1rem);
    text-align: right;
    overflow: visible;
    white-space: nowrap;
}

.stock {
    width: 100%;
    margin: 1rem auto;
}
.stock-seek-bar {
    position: relative;

    width: 90%;
    height: 22px;
    margin: 10px auto;
    padding: 3px;

    background-color: var(--stock-seek-bar-color);
}
.stock-seek-bar-inner {
    position: absolute;

    width: 100%;
    height: 16px;
    top: 3px;
    left: 3px;
}
.stock-seek-bar-inner-75-percent {
    background-color: var(--stock-seek-bar-inner-75-percent-color);
}
.stock-seek-bar-inner-50-percent {
    background-color: var(--stock-seek-bar-inner-50-percent-color);
}
.stock-seek-bar-inner-25-percent {
    background-color: var(--stock-seek-bar-inner-25-percent-color);
}
.stock-seek-bar-inner-rainbow {
    width: 100%;
    background: linear-gradient(to right,
    red,
    orange,
    yellow,
    green,
    aqua,
    blue,
    purple);
}
.stock-value {
    margin: 10px auto;
    text-align: center;
    color: var(--stock-value-color);
    font-weight: bold;
}
.slide-dots{
    position: absolute;
    height: 3rem;
    bottom: -20px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slide-dots li{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slide-dots li button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slide-dots li button:hover,
  .slide-dots li button:focus{
    outline: none;
  }
  .slide-dots li button:hover:before,
  .slide-dots li button:focus:before{
    opacity: 1;
  }
  .slide-dots li button:before{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: var(--slide-dots-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slide-dots li.slick-active button:before{
    opacity: .75;
    color: var(--slide-dots-active-color);
  }

  .gacha-button-disabled {
    opacity: 0.5;
  }