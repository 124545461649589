.point-change-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);

  width: calc(90% - 6rem);
  max-width: 600px;
  height: auto;
  max-height: calc(90vh - 6rem);

  margin: 0 auto;
  padding: 3rem;

  color: var(--point-change-modal-font-color);

  background-color: var(--point-change-modal-color);
  border-radius: 16px;

  overflow-y: auto;

  z-index: 1001;
}

.point-change-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
}

.point-change-modal-content {
  text-align: center;
  margin: 1rem auto;
}

.point-change-modal-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.point-change-modal-text {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.point-change-modal-text-note {
  text-align: left;
  font-size: 1rem;
  color: #999999;
}

.point-change-modal-button button {
  width: 100%;
  margin: 0.5rem auto;
  padding: 1rem;
  
  border: none;
  border-radius: 8px;

  font-size: 1.2rem;
  cursor: pointer;
}

.point-change-modal-button-charge {
  color: var(--point-change-modal-button-charge-font-color);
  background: var(--point-change-modal-button-charge-color) 0% 0% no-repeat padding-box;
}

.point-change-modal-button-close {
  color: var(--point-change-modal-button-close-font-color);
  background: var(--point-change-modal-button-close-color) 0% 0% no-repeat padding-box;
}

.point-change-modal-change-info {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  height: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  margin-top: 1rem;

  clear: both;
}

.point-change-modal-change-info-title {
  width: auto;
  height: 2.5rem;

  font-size: 1.2rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  text-align: left;

  float: left;
}

.point-change-modal-change-info-content {
  min-width: 30%;
  max-width: 60%;
  height: 2.5rem;

  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;

  float: right;
}

.point-change-modal-change-info-image {
  width: 2.5rem;
  margin-right: 0.5rem;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--line-color);
}

.color-red {
  color: #ff0000;
}