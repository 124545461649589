.gacha {
    width: 100%;
}

.gacha-package {
    width: 95%;

    margin: 1rem auto;

    background-color: var(--gacha-package-color);
}

.gacha-package-image {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: white;
}

.gacha-package-image img {
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
}

.gacha-back {
    position: relative;
    padding: 1rem;
    height: 4.5rem;

    font-weight: bold;
}

.gacha-back button {
    position: absolute;
    /* width: 15rem; */
    height: 3rem;
    padding-left: 3rem;
    padding-right: 2rem;

    background: var(--gacha-back-button-color) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 8px;
    color: var(--gacha-back-font-color);
    
    text-align: left;
}

.gacha-back button::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 1rem;
    width: 0.5rem;
    height: 0.5rem;
    margin: -0.25rem 0 0 0;
    border-top: solid 2px var(--gacha-back-color);
    border-right: solid 2px var(--gacha-back-color);
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
}

.gacha-button {
    text-align: center;

    background-color: white;
    
    position: fixed;
    bottom: 0;
    left: center;
    width: 100%;
    max-width: 600px;
    height: 13vh;

    z-index: 999;
}

.gacha-button-top {
    text-align: center;

    background-color: white;
    
    width: 100%;
    max-width: 600px;
    height: 13vh;
}

.gacha-button button, .gacha-button-top button {
    margin: 2vh 1vh;

    width: 40%;
    height: 8vh;

    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;

    font-size: 1.8vh;
    font-weight: bold;

    box-shadow: inset 5px 5px 9px rgba(255, 255, 255, 0.5),inset -5px -5px 9px rgba(255, 255, 255, 0.5);
}

.gacha-button-1 {
    background: var(--gacha-button-1-color) 0% 0% no-repeat padding-box;
    color: var(--gacha-button-1-font-color);
}

.gacha-button img, .gacha-button-top img{
    width: 4.5vh;
    height: 4.5vh;
    margin-right: 5px;
}

.gacha-button-10 {
    background: var(--gacha-button-10-color) 0% 0% no-repeat padding-box;
    color: var(--gacha-button-10-font-color);
}

button.gacha-button-all {

    width: 94%;

    margin-top: 1rem;

    background: var(--stock-seek-bar-inner-25-percent-color) 0% 0% no-repeat padding-box;
    color: var(--gacha-button-1-font-color);
}

.gacha-button-disabled {
    opacity: 0.5;
}

.gacha-info {
    width: 100%;
    margin: 3rem 0;
    padding: 1rem 0;

    text-align: center;

    /* background-color: var(--gacha-info-color); */
    background-image: var(--gacha-background-image);
    background-repeat: repeat;
    background-size: 100%;
}

.gacha-info-detail {
    display: inline-block;

    width: 100%;
    margin: 2rem 0;

    font-size: 1.6rem;
}

.gacha-info-rank-image {
    width: 30%;
    margin: 0 auto;
}

.gacha-info-rank-image img {
    width: 100%;
    border-radius: 16px;
}

.gacha-info-card-images {
    width: 60%;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.gacha-info-card-image {

    height: 150px;
    aspect-ratio: 0.716 !important;
}

.gacha-info-card-images img{
    margin: 0 auto;

    height: 150px;
    aspect-ratio: 0.716 !important;
}

.gacha-info-card-image-inner {
    position: relative;

    margin: 0 auto;

    height: 150px;
    aspect-ratio: 0.716 !important;
}


.gacha-info-card-image-rank1 {
    position: relative;
    margin: 0 auto;
    margin-bottom: 1rem;
    width: 100%;

    aspect-ratio: 0.716;
}

.gacha-info-card-image-rank1 img {
    width: 100%;
    height: 100%;
}

.gacha-info-card-image-rank2 {
    position: relative;
    margin: 0 1.25%;
    margin-bottom: 1rem;
    width: 60%;

    float: left;

    aspect-ratio: 0.716;
}

.gacha-info-card-image-rank2 img {
    width: 100%;
    height: 100%;
}

.gacha-info-card-image-rank3 {
    position: relative;
    margin: 0 0.5%;
    margin-bottom: 1rem;
    width: 40%;

    float: left;

    aspect-ratio: 0.716;
}

.gacha-info-card-image-rank3 img {
    width: 100%;
    height: 100%;
}


.gacha-info-card-psa {
    position: absolute;
    padding: 0.01rem 0.25rem;

    color: var(--gacha-button-1-font-color);

    background: var(--gacha-button-10-color) 0% 0% no-repeat padding-box;
    
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 0.8;
}

.gacha-info-card-image-rank1 .gacha-info-card-psa {
    top: 0;
    right: 0;
    font-size: 2.5rem;
}

.gacha-info-card-image-rank2 .gacha-info-card-psa {
    top: 0;
    right: 0;
    font-size: 1.5rem;
}

.gacha-info-card-image-rank3 .gacha-info-card-psa {
    top: 0;
    right: 0;
    font-size: 1rem;
}

.gacha-info-card-number-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
}

.gacha-info-card-number {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;

    font-size: 2rem;

    padding: 0.01rem 0.25rem;

    color: var(--gacha-button-1-font-color);

    background: var(--gacha-info-card-number-color) 0% 0% no-repeat padding-box;
    
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 0.8;
}

.gacha-movie {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100svh;
    
    margin: 0;

    z-index: 1001;
}

.gacha-movie video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

@media screen and (min-width: 600px) {
    .gacha-movie {
        position: fixed;
        top: 0;
        left: 0;
    
        width: 100%;
        height: 100svh;
        
        margin: 0 auto;
    
        z-index: 1001;

        background-color: #000000;
    }

    .gacha-movie video {
        width: 100%;
        height: 100vh;
        object-fit: contain;
    }
}

.gacha-movie-button button {
    position: absolute;
    bottom: 2rem;
    right: 2rem;

    width: 8rem;
    height: 2.5rem;

    border: 0.5px solid var(--gacha-movie-button-border-color);
    background: var(--gacha-button-1-font-color) 0% 0% no-repeat padding-box;
    color: var(--gacha-movie-button-font-color);
    
    border-radius: 8px;
    opacity: 0.8;

    z-index: 1002;
}

.gacha-movie-button button::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    margin: -0.25rem 0 0 0;
    border-top: solid 2px var(--gacha-movie-button-border-color);
    border-right: solid 2px var(--gacha-movie-button-border-color);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.gacha-info-note {
    width: 90%;
    margin: 2rem auto;
    padding: 1rem 0;

    text-align: center;
}

.gacha-info-note-title {
    font-weight: bold;
    color: var(--gacha-info-note-title-color);
}

.gacha-info-note-text {
    margin: 1rem 0;
}

.gacha-won {
    width: 100%;
    margin: 3rem 0;
    padding: 1rem 0;

    color: var(--gacha-won-font-color);
}

.gacha-won-title {
    margin: 1rem 0 2rem;
    font-size: 1.5rem;
    font-weight: bold;

    text-align: center;
}

.gacha-won-description {
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 1rem;
}

.gacha-won-note {
    font-size: 1.1rem;
    font-weight: bold;
    color: #BCBCBC;
    margin-left: 1rem;
}

.gacha-won-cards {
    width: 100%;
    margin: 0.5rem 0;
    padding-bottom: 50vh;
}

.gacha-won-card {
    position: relative;

    width: 90%;
    height: 8.5rem;

    margin: 2rem auto;
    padding: 1rem 0;

    border: 4px solid var(--gacha-won-card-color);
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;

    background-color: var(--gacha-won-card-color);
}

.gacha-won-card-checkbox {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;

    width: 3rem;
    height: 3rem;

    border: none;
    border-radius: 1rem;

    background: transparent url('icon/unchecked.png') 0% 0% no-repeat padding-box;
}

.gacha-won-card-checkbox input {
    visibility: hidden;
}

.gacha-won-card-checked {
    border: 4px solid var(--gacha-won-card-checked-color);
}

.gacha-won-card-checked .gacha-won-card-checkbox{
    background: transparent var(--gacha-won-card-checkbox) 0% 0% no-repeat padding-box;
}

.gacha-won-card-no-check .gacha-won-card-checkbox{
    background: none;
}

.gacha-won-card-image {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
}

.gacha-won-card-image img{
    height: 8rem;

    border-radius: 0.3rem;
    
    aspect-ratio: 0.716;
}

.gacha-won-card-balance-image {
    position: absolute;
    top: 50%;
    left: 6rem;
}

.gacha-won-card-balance-image img {
    width: 2.5rem;
    height: 2.5rem;

}

.gacha-won-card-name {
    width: 60%;
    min-height: 1.5rem;
    max-height: 3rem;

    font-size: 1rem;
    font-weight: bold;
    
    float: right;
    clear: both;
    overflow: hidden;        /* 追加 */
    text-overflow: ellipsis; /* 追加 */
    white-space: nowrap;     /* 追加 */
}

.gacha-won-card-rarity {
    width: 60%;
    height: 1.5rem;

    font-size: 1rem;
    font-weight: bold;

    color: var(--card-rarity-font-color);
    
    float: right;
    clear: both;
}

.gacha-won-card-point {
    width: 60%;
    height: 1.5rem;

    font-size: 1rem;
    font-weight: bold;
    
    float: right;
    clear: both;
}

.gacha-won-card-period {
    width: 60%;
    height: 1rem;

    font-size: 0.8rem;
    margin-top: 0.5rem;
    
    float: right;
    clear: both;

    opacity: 0.3;
}

.gacha-won-change-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    
    width: 98%;
    max-width: 600px;

    margin: 0 auto;

    z-index: 999;
}

.gacha-won-change {
    margin: 0 auto;
    padding: 2rem;

    width: calc(100% - 1rem);
    max-width: 600px;
    height: 27rem;

    background: var(--gacha-won-change-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029;
    border-radius: 16px 16px 0px 0px;

    z-index: 999;
}

.gacha-won-change-point {
    width: 50%;

    color: var(--gacha-won-change-point-color);

    float: left;
}

.gacha-won-change-title {
    font-size: 1.2rem;
}

.gacha-won-change-point-text {
    font-size: 2rem;
    font-weight: bold;
    color: var(--gacha-won-change-point-text-color);
}

.gacha-won-change-number-text {
    font-size: 1.1rem;
}

.gacha-won-change-ship {
    width: 50%;

    color: var(--gacha-won-change-point-color);

    float: right;
}

.gacha-won-change-button {
    margin: 8rem 0 0;
}

.gacha-won-change-ship-info {
    position: relative;
    width: calc(100% - 1rem);
    height: 5.5rem;

    margin: 0.5rem 0 0;
    padding: 0.5rem 2rem 1.5rem 1rem;

    background: var(--gacha-won-change-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--gacha-won-change-ship-info-color);
    border-radius: 8px;

    font-size: 1.1rem;
    color: var(--gacha-won-change-ship-info-font-color);

    cursor: pointer;
}

.gacha-won-change-ship-info div {
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gacha-won-change-ship-info::after {
    content: "";
    position: absolute;
    right: 1rem;
    top: calc(50% - 0.4rem);
    width: 0.8rem;
    height: 0.8rem;
    border-top: solid 2px var(--gacha-won-change-ship-info-font-color);
    border-right: solid 2px var(--gacha-won-change-ship-info-font-color);
    transform: rotate(45deg);
}

.gacha-won-change-ship-text{
    text-align: center;
    color: var(--gacha-won-change-ship-text-color);
}

.gacha-won-change-button button {
    width: 100%;
    height: 4.5rem;

    border-radius: 8px;

    margin: 0.5rem auto;

    font-size: 1.5rem;
}

.gacha-won-change-button-change-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gacha-won-change-button button.gacha-won-change-button-change-half {
    width: calc(50% - 0.6rem);
    height: 4.5rem;

    border-radius: 8px;

    margin: 0.5rem auto;

    font-size: 1.5rem;
}

.gacha-won-change-button-change {

    color: var(--gacha-won-change-button-change-color);
    border: 0.5px solid var(--gacha-won-change-button-change-color);
    background: var(--gacha-won-change-color) 0% 0% no-repeat padding-box;
}

.gacha-won-change-button-change-active {
    color: var(--gacha-won-change-button-ship-font-color);
    background: var(--gacha-won-change-button-ship-color);
    border: none;
}

.gacha-won-change-button-ship {
    color: var(--gacha-won-change-button-ship-font-color);
    background: var(--gacha-won-change-button-ship-color);
    border: none;
}

.gacha-won-change-button-ship-active {
    color: var(--gacha-won-change-button-ship-active-font-color);
    background: var(--gacha-won-change-button-change-color) 0% 0% no-repeat padding-box;
}

.gacha-won-cards-note {
    width: 90%;
    margin: 0 auto;

    font-size: 1rem;
    color: #BCBCBC;
}

.gacha-won-loading {
    margin-top : 2rem;
    text-align: center;
}

.package-sold-out {
    opacity: 0.5;
    position: relative;
}

/* .package-sold-out::after {
    content: '完売';
    position: absolute;
    top: 35%;
    width: 100%;

    text-align: center;

    font-size: 3rem;
    font-weight: 600;
    color: #000;

    z-index: 999;
} */

.package-end {
    opacity: 0.5;
    position: relative;
}

/* .package-end::after {
    content: '期間終了';
    position: absolute;
    top: 35%;
    width: 100%;

    text-align: center;

    font-size: 3rem;
    font-weight: 600;
    color: #000;

    z-index: 999;
} */

.gacha-info-detail-image {
    width: 100%;
    margin: 0 auto;
}

.gacha-info-detail-image img {
    width: 100%;
}

.gacha-hr {
    width: 96%;
    margin: 2rem auto;
    border: 1px solid var(--body-font-color);
}

.gacha-info-open-button {
    width: 100%;
    margin: 1rem auto;
    text-align: center;
    cursor: pointer;
}